#selectSection{
    width: 93%;
}
td div{
    background-color: white;
    padding-bottom: 5px;
}
#quantity{
    background-color: white;
}
#editReceivedData{
    display: flex;
    margin-left: auto;
    background-color: rgb(78, 145, 233);
    color: white;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 5px;
    
}

#editReceivedData:hover{
    background-color: rgb(113, 90, 216);
}

#editReceivedData:active{
    background-color: black;
}

#editQuantity{
    width: 150px;
    display:flex;
    justify-content:flex-start;
    flex-direction:row
}
