
.addWorker {
    max-width: 60%;
    margin: 0 auto;
    margin-top: 40px;
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 30px;
    padding-bottom: 20px;
    background-color: white;
    box-shadow: 5px 10px 15px rgba(0,0,0,0.1);
  }
  .addWorker label {
    background-color: white;
    text-align: left;
    padding: 20px;
    /* display: block; */
    
  }
  
  .addWorker input, .create textarea, .create select {
    width: 30%;
    padding: 10px 20px 10px 20px;
    margin: 10px 0;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-sizing: border-box;
    /* display: block; */
  }

.addWorker button {
    background: rgb(78, 145, 233);
    color: #fff;
    border: 0;
    margin-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 8px;
    cursor: pointer;
    width: 30%;
  }
  
  .addWorker button:hover {
    background: rgb(113, 90, 216)
  }
  
  .addWorker button:active {
    background: black
  }
  

  .addWorkerTable {
      max-width: 800px;
      margin: 0 auto;
      background-color: white;
      box-shadow: 5px 10px 15px rgba(0,0,0,0.1);
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 20px;
      margin-top: 50px;
      padding-bottom: 20px;
      margin-bottom: 100px;
      text-align: center;
      margin-top: 10px;
  }
 

textarea{
  height: 50px;
  border-radius: 5px;
}

.addWorkerTable td {
    background-color: white;
    padding-bottom: 5px;
    text-align: left;
    border: 0px solid white;
    border-bottom:0px solid white; 
    box-shadow: 0px 0px 0px rgba(0,0,0,0.1);
}

.addWorkerTable input{
    width: 90%;
    border-radius: 5px;
    border: 1px solid;
}


.addWorkerTable button {
  background: rgb(78, 145, 233);
  color: #fff;
  border: 0;
  margin-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 8px;
  cursor: pointer;
  width: 40%;
}

.addWorkerTable button:hover {
  background: rgb(113, 90, 216)
}

.addWorkerTable button:active {
  background: black
}

#date {
  background-color: white;
}