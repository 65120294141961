/* .userDetails {
    font-family: arial;
    font-size: 24px;
    margin: 0 auto;
    margin-top: 100px;
    width: 40%;
    height: 400px;
    border-bottom: 1px solid #f2f2f2;
    background-color: white;
    box-shadow: 5px 10px 15px rgba(0,0,0,0.1);
  
    /* Center vertically and horizontally */
     /* display: flex; 
     justify-content: center;
     align-items: center;
  }   */
/*   
  
  .userContent {
    font-size: 24px;
    background-color: white;
    height: 300px;
    width: 25%;
    height: 300px;
    margin: 50px auto;
   padding: 20px;
   border-bottom: 1px solid #f2f2f2;
   box-shadow: 3px 6px 10px rgba(0,0,0,0.1);
  } */

  .userContent{
    margin-top: 50px;
  }
  
  #content1, #content3, #content5 {
    font-weight: 900;
    background-color: white;
   
  }
  
  #content2, #content4, #content6 {
    background-color: white;
    display: block;
    padding-bottom: 10px;
  }
  
  
  .userButton{
    width: 100%;
    font-size: 20px;
    font-weight: 900;
  }
  
  .userContent button  {
    background: rgb(78, 145, 233);
    color: #fff;
    border: 0;
    margin-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 8px;
    cursor: pointer;
  }
  
  .userContent button:hover {
    background: rgb(113, 90, 216)
  }
  
  .userContent button:active {
    background: black
  }