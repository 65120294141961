
.allUsers{
    display: flex;
    align-items: center;
    justify-content: center;
    /* border: 1px solid black; */
    /* margin-left: 100px;
    margin-right: 100px; */
}

.allUsersContent {
    display: block;
    /* padding: 10px; */
}