.workerTableInfo{
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid red;
}

#title{
    font-weight: 500;
}

#title2{
  font-weight: 900;
  font-size: 24px;
}
.heading {
  text-align: center;
  font-weight: 900;
  font-size: 24px;
  margin-bottom: 20px
}

.workerTable {
    max-width: 800px;
    margin: 0 auto;
    background-color: white;
    box-shadow: 5px 10px 15px rgba(0,0,0,0.1);
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    margin-top: 50px;
    padding-bottom: 20px;
    margin-bottom: 100px;
    text-align: center;
    margin-top: 10px;
}


#workerTable{
  background-color: white;
}


.workerTable td {
    background-color: white;
    padding-bottom: 5px;
    text-align: left;
    border: 0px solid white;
    border-bottom:2px solid ghostwhite; 
    box-shadow: 0px 0px 0px rgba(0,0,0,0.1);
}


.workerTable button {
    background: rgb(78, 145, 233);
    color: #fff;
    border: 0;
    margin-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 8px;
    cursor: pointer;
    width: 40%;
  }
  
 
  .workerTable button:hover {
    background: rgb(113, 90, 216)
  }
  
  .workerTable button:active {
    background: black
  }
  
  
