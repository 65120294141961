.spinner {
    background: rgb(78, 145, 233);
    color: #fff;
    border: 3px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 5px; 
    padding-bottom: 5px;
    border-radius: 8px;
    width: 280px;
    margin: 20px auto;
  }