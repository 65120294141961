.parent{
    display: inline-flex;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-radius: 10px;
    background-color: white;
    border: 0;
    border: 1px solid #f2f2f2; 
    box-shadow: 3px 5px 7px rgba(0,0,0,0.1);
    width: 90%;
    margin: 10px
}


.parent:hover{
    background:  rgb(78, 145, 233);
    color: white;
}

.parent:active{
    background: black
} 

.buttonwrapper {
    font-size: 24px;
    font-weight: 900;
    padding-left: 10px;
    padding-top:10px;
    padding-bottom: 10px;
    padding-right: 30px;
    border: 0;
    /* border: 1px solid #f2f2f2;
    box-shadow: 3px 5px 7px rgba(0,0,0,0.1); */
    border-radius: 10px;
    background-color: white;
    width:100%;
}

  .buttonwrapper:hover {
    background:  rgb(78, 145, 233);
    color: white
  }

  .buttonwrapper:active{
    background: black
  }

  .iconwrapper{
    font-size: 40px;
    float: left;
  }

  


