.leftBar {
    float: left;
    width: 20%;
    /* border: 1px solid black; */
  }
  
  .rightBar {
    float: right;
    width: 20%;
    /* border: 1px solid black; */
   
  }
  
  .rightBarContent{
    display: block;
    padding: 20px
  }
  
  .rightBarContent button {
    background: rgb(78, 145, 233);
    color: #fff;
    border: 0;
    margin-top: 20px;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 8px;
    cursor: pointer;
    font-size: 20px;
    font-weight: 900;
    width: 100%
  }
  
  
  .rightBarContent button:hover {
    background: rgb(113, 90, 216)
  }
  
  .rightBarContent button:active {
    background: black
  }
  
  
  .workers {
    display: flex;
    align-items: center;
    justify-content: center;
   
  }
  
  .workersContent{
    display: block;
    padding: 10px;
  }

  table {
    width: 800px;
    height: 200px;
    padding-bottom: 10px;
  }
    
  th {
    border-bottom: 1px solid black;
    padding: 15px;
    background-color: white;
    border-bottom: 2px solid #f2f2f2;
    box-shadow: 2px 5px 8px rgba(0,0,0,0.1);
  }
  
  
  
  td {
    text-align: center;
    border-bottom: 1px solid black;
    padding: 15px;
    background-color: white;
    border-bottom: 2px solid #f2f2f2;
    box-shadow: 2px 5px 8px rgba(0,0,0,0.1);
  }
  
  td button {
    background-color: rgb(78, 145, 233);
    color: #fff;
    border: 0;
    border-radius: 5px;
    
  }
  
  td button:hover{
    background: rgb(113, 90, 216);
    cursor: pointer;
  }
  
  td button:active{
    background-color: black;
  }

  .changePageFooter {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;
    /* border: 1px solid black; */
     background-color: white; 
    margin-top: 10px;
    margin-bottom: 200px;
  }

  #inputFooter {
    width: 50px;
    border: 1px solid ghostwhite;
    text-align: center;
  }
 #backButton{
   margin-right: 20px;
   padding-left: 20px;
   padding-right: 20px;
   padding-top: 10px;
   padding-bottom: 10px;
   background-color: white;
   font-weight: 900;
   border: 0px;
   border-radius: 5px;
   border-bottom: 2px solid #f2f2f2;
   box-shadow: 2px 5px 8px rgba(0,0,0,0.1);
   cursor: pointer;
 }

 #nextButton{
  margin-left: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: white;
  font-weight: 900;
  border: 0px;
  border-radius: 5px;
  border-bottom: 2px solid #f2f2f2;
  box-shadow: 2px 5px 8px rgba(0,0,0,0.1);
  cursor: pointer;
}

 #backButton:hover, #nextButton:hover {
    background: rgb(113, 90, 216);
    color: white;
 }

 #backButton:active, #nextButton:active {
  background: black;
  color: white;
}

 #totalPage {
   background-color: white;
   padding-left: 10px;
 }