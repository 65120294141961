.addBuyer {
    max-width: 800px;
    background-color: white;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
    
}

.addBuyer label{
    background-color: white;
    font-size: 18px;
    padding: 20px;
}

.addBuyer input{
    border-radius: 5px;
    border: 1px solid black;
    padding: 5px;
    margin-bottom: 20px;
}

#addButton {
    display: block;
    width: 50%;
    margin: 20px auto;
    font-size: 18px;
    font-weight: 700;
    background: rgb(78, 145, 233);
    color: #fff;
    border: 0;
    margin-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 8px;
    cursor: pointer;
}

#updateButton {
    display: block;
    width: 50%;
    margin: 20px auto;
    font-size: 18px;
    font-weight: 700;
    background: aquamarine;
    color: black;
    border: 0;
    margin-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 8px;
    cursor: pointer;
}

#updateButton:hover{
    background-color:green;
    color:white
}

#updateButton:active{
    background-color:black
}

#updateQuantityButton {
    display: block;
    width: 50%;
    margin: 20px auto;
    font-size: 18px;
    font-weight: 700;
    background: red;
    color: #fff;
    border: 0;
    margin-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 8px;
    cursor: pointer;
}

#updateQuantityButton:hover{
    background-color: rgb(78, 145, 233);
    color: white
}

#updateQuantityButton:active{
    background-color:black
}