
.buyerName {
    background-color: white;
    border: 0px;
    width: 100%;
    margin-top:20px;
    margin-bottom: 200px
}

.buyerName td{
    background-color: white;
    font-size: 20px;
    cursor: pointer;
}

.buyerName td:hover {
    background-color: turquoise;
}

.buyerName td:active{
    background-color: black;
    color:white
}

.storeHome {
    margin-top: 150px;
}

li {
    display: inline-block;
    margin: 10px; 
    border-bottom: 1px solid #f2f2f2;
    border-radius: 5px;
    background-color: white;
    box-shadow: 2px 5px 8px rgba(0,0,0,0.1); 
    width:150px;
    padding:20px;
    margin-top: 5px;
    text-align: center;
    font-size: 20px;
  }

 ul{
    width: 80%; 
    margin: 0 auto;
}

h1{
    text-align: center;
    padding: 20px
}

li:hover{
    background-color: red;
    color:white;
    cursor: pointer
}