@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');

/* base styles */
* {
  margin: 0;
  font-family: "Quicksand";
  color: #333;
  background-color: ghostwhite;
}

.navbar {
  padding: .02% 100px;
  display: flex;
  align-items: center;
  /* max-width: 600px; */
  margin: 0 auto;
  margin-bottom: 10px;
  border-bottom: 1px solid #f2f2f2;
  background-color: white;
  box-shadow: 1px 3px 5px rgba(0,0,0,0.1);
}
.navbar h1 {
  color: #f1356d;
  background-color: white;
  cursor: pointer;
}
.navbar .links {
  background-color: white;
  margin-left: auto;
}
.navbar .links span {
  background-color: white;
}

.navbar .links button {
  background-color: rgba(68, 109, 107);
  padding: 10px;
  padding-left: 20px;
  padding-right:20px;
  margin-left:20px;
  border: 0;
  border-radius: 10px;
  margin-left: 30;
  font-size: 15px;
  font-weight: 900;
  color:white;
  cursor: pointer;
}

.navbar .links button:hover{
  background-color: rgb(68, 103, 199);
}

.navbar .links button:active{
  background-color: black;
}

.navbar .links #user  {
  background-color: white;
  color:#333
}

.navbar a {
  background-color: white;
  margin-left: 16px;
  text-decoration: none;
  padding: 6px;
}
.navbar a:hover {
  color: #f1356d;
  
}

/* Login form styels */

.login {
  max-width: 350px;
  margin: 0 auto;
  margin-top: 50px;
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 30px;
  padding-bottom: 50px;
  background-color: white;
  box-shadow: 5px 10px 15px rgba(0,0,0,0.1);
}
.login label {
  background-color: white;
  text-align: left;
  display: block;
}

.login input, .create textarea, .create select {
  width: 100%;
  padding: 10px 20px 10px 20px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-sizing: border-box;
  display: block;
}
/* button class */
.block{
  width: 100%;
  font-size: 20px;
  font-weight: 900;

}
.login button {
  background: rgb(78, 145, 233);
  color: #fff;
  border: 0;
  margin-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 8px;
  cursor: pointer;
}

.login button:hover {
  background: rgb(113, 90, 216)
}

.login button:active {
  background: black
}



/* error styling */
div .error {
  padding: 10px;
  color: red;
  background-color: white;
  margin: 20px 0;
}

div .success {
  padding: 10px;
  color: green;
  background-color: white;
  margin:20px 0;

}

/* User details styling */



/* Change Password Form */
.userContent2 {
  max-width: 350px;
  margin: 0 auto;
  margin-top: 50px;
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 30px;
  padding-bottom: 50px;
  background-color: white;
  box-shadow: 5px 10px 15px rgba(0,0,0,0.1);
}
.userContent2 label {
  background-color: white;
  text-align: left;
  display: block;
  padding-bottom: 10;
}

.userContent2 input {
  width: 100%;
  padding: 10px 20px 10px 20px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-sizing: border-box;
  display: block;
}


.userContent2 button {
  background: rgb(78, 145, 233);
  color: #fff;
  border: 0;
  margin-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 8px;
  cursor: pointer;
}

.userContent2 button:hover {
  background: rgb(113, 90, 216)
}

.userContent2 button:active {
  background: black
}

/* HrStaff Home Page */


.searchBar {
  width: 400px;
  padding: 15px;
  border-radius: 10px;
  border: 0;
  border-bottom: 1px solid #f2f2f2;
  background-color: white;
  box-shadow: 1px 3px 5px rgba(0,0,0,0.1);
  margin: 0 auto 20px auto;
  display: block;
}




/* Add Worker Page Form Styling */


/* button class */
.block{
  width: 100%;
  font-size: 20px;
  font-weight: 900;

}
/* error styling */
div .error {
  padding: 10px;
  color: red;
  background-color: white;
  margin: 20px 0;
}

div .success {
  padding: 10px;
  color: green;
  background-color: white;
  margin:20px 0;

}
