
.uploadContent{
    display: inline-block;
    align-items: center;
    justify-content: center;
    padding-left: 100px;
    padding-right: 100px;
    padding-bottom: 100px;
    background-color: white;
    border-bottom: 2px solid #f2f2f2;
    box-shadow: 2px 5px 8px rgba(0,0,0,0.1);
}

.titleImport{
    font-size: 24px;
    font-weight: bold;
    padding: 20px;
    background-color: white;
    text-align: center;
}
.chooseFile{
    margin-top: 50px;
    margin-bottom: 50px;
    padding:10px;
    border-radius: 5px;
}

#upload{
    text-align: center;
    background-color: white;
    margin-bottom: 20px;
}

#upload button{
    background-color: rgb(78, 145, 233);
    color: #fff;
    border: 0;
    border-radius: 5px;
    cursor: pointer;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 16px;
    width: 150px;
}

#upload button:hover{
    background: rgb(113, 90, 216);
    cursor: pointer;
}

#upload button:active{
    background-color: black;
}

#cancel{
    text-align: center;
    background-color: white;
    
}

#cancel button{
    border: 0px;
    border-radius: 5px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top:10px;
    padding-bottom: 10px;
    font-size: 16px;
    color: red;
    background-color: rgb(255,204,203);
    width: 150px;
    cursor: pointer;
}

#cancel button:hover{
    background-color: red;
    color:white
}

#cancel button:active{
    background-color: black;
}

.succErr{
    text-align:center;
}