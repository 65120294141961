.mainContent{
    display: flex;
    justify-content: space-evenly;   
    
}

.blockSection{
    padding: 30px;
    border-radius: 8px;
    margin: 20px;
    background-color: white;
    margin-top: 150px;
    border-bottom: 1px solid #f2f2f2;
    box-shadow: 2px 5px 8px rgba(0,0,0,0.1);
    cursor: pointer;
    font-size: 24px;
}



.blockSection:hover{
    background-color: rgb(78, 145, 233);
    color:white;
}

.blockSection:active{
    background-color:black;
    color:white;
}


