.cardContent{
    font-size: 24px;
    background-color: white;
    /* border: 1px solid red; */
    width: 25%;
    height: 200px;
    margin: 0 auto;
    padding: 20px;
    padding-bottom: 140px;
    border-bottom: 1px solid #f2f2f2;
    box-shadow: 3px 6px 10px rgba(0,0,0,0.1);
}

#cardContent1, #cardContent3, #cardContent5 {
    font-weight: 900;
    background-color: white;
   
  }
  
  #cardContent2, #cardContent4, #cardContent6 {
    background-color: white;
    display: block;
    padding-bottom: 10px;
  }

  .dropDown {
    border-radius: 8px;
    /* border: 1px solid red; */
  }

  .cardButton{
    width: 100%;
    font-size: 20px;
    font-weight: 900;
  }

  .cardContent button  {
    background: rgb(78, 145, 233);
    color: #fff;
    border: 0;
    margin-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 8px;
    cursor: pointer;
  }
  
  .cardContent button:hover {
    background: rgb(113, 90, 216)
  }
  
  .cardContent button:active {
    background: black
  }

  .cardSuccess {
    background-color: white;
    color: green;
    font-size: 16px;
    margin: 10px ;
    text-align: center;
  }

  .cardError {
    background-color: white;
    color: red;
    font-size: 16px;
    margin: 10px;
    text-align: center;
  }