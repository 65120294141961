.addPurchaseOrder table{
    padding: 0;
    /* width: 80%; */
    margin: 0 auto
}

.addPurchaseOrder td{
    padding: 0;
}

.addPurchaseOrder input {
    width: 200px;
}