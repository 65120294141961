.propertyList{
    margin-top:100px;
}
.propertyList li {
    display: inline-block;
    margin: 10px; 
    border-bottom: 1px solid #f2f2f2;
    border-radius: 5px;
    background-color: white;
    box-shadow: 2px 5px 8px rgba(0,0,0,0.1); 
    width:200px;
    margin-top: 5px;
    text-align: center;
    font-size: 20px;
  }

 .propertyList ul{
    width: 80%; 
    margin: 0 auto;
   
}

h1{
    text-align: center;
    padding: 20px
}

li:hover{
    background-color: red;
    color:white;
    cursor: pointer
}