.workerTableInfo{
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid red;
}

#title{
    font-weight: 500;
}

.heading {
  text-align: center;
  font-weight: 900;
  font-size: 24px;
}

.heading2{
  text-align: center;
  font-weight: 500;
  font-size: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.rosterTable {
    max-width: 800px;
    margin: 0 auto;
    background-color: white;
    box-shadow: 5px 10px 15px rgba(0,0,0,0.1);
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    margin-top: 50px;
    padding-bottom: 20px;
    text-align: center;
    margin-top: 10px;
}


#rosterTable{
  background-color: white;
}

.rosterTable td {
    background-color: white;
    padding-bottom: 5px;
    text-align: center;
    border: 0px solid white;
    border-bottom:2px solid ghostwhite; 
    box-shadow: 0px 0px 0px rgba(0,0,0,0.1);
}


.rosterTable button {
    background: rgb(78, 145, 233);
    color: #fff;
    border: 0;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 4px;
    cursor: pointer;
    width: 40%;
  }
  
 
  .rosterTable button:hover {
    background: rgb(113, 90, 216)
  }
  
  .rosterTable button:active {
    background: black
  }
  
  .changePage {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;
    /* border: 1px solid black; */
     background-color: white; 
    margin: 0 auto;
    margin-top: 10px;
    margin-bottom: 200px;
    width: 840px
  }

  .inputDate {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin: 10px;
    background-color: white;
    border: 0px;
    border-radius: 5px;
  }